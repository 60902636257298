<template>
  <div class="changePswd">
    <el-image
      class="changePswd_bg"
      :src="require('./../../public/img/login/login_bg.png')"
      fit="cover"
    >
    </el-image>
    <div class="changeBox">
      <el-form class="formBox" :model="formData" :rules="rules" ref="formData">
        <el-tabs class="typeTabs" v-model="activeType" @tab-click="handleClick">
          <el-tab-pane :label="i18nData.typephone" name="phone"></el-tab-pane>
          <el-tab-pane :label="i18nData.typeemail" name="email"></el-tab-pane>
        </el-tabs>
        <template v-if="activeType == 'phone'">
          <el-form-item class="formItem" prop="phone">
            <el-input
              class="input"
              v-model="formData.phone"
              :placeholder="i18nData.phonetips"
              ><template slot="prefix">+86</template></el-input
            >
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item class="formItem" prop="email">
            <el-input
              class="input"
              v-model="formData.email"
              :placeholder="i18nData.emailtips"
            ></el-input>
          </el-form-item>
        </template>
        <div class="codeBox">
          <el-form-item class="formItem" prop="code">
            <el-input
              class="input"
              v-model="formData.code"
              :placeholder="i18nData.codetips"
            ></el-input>
          </el-form-item>
          <span class="code-img">
            <el-button
              @click="getCode(eventType)"
              type="primary"
              plain
              :disabled="isDisabled"
              >{{
                isDisabled
                  ? countText.count + i18nData.changecode
                  : i18nData.code
              }}</el-button
            >
          </span>
        </div>
        <el-form-item class="formItem" prop="password">
          <el-input
            class="input"
            v-model="formData.password"
            :placeholder="i18nData.pswtips"
            autocomplete="off"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item class="formItem" prop="pswdAgain">
          <el-input
            class="input"
            v-model="formData.pswdAgain"
            :placeholder="i18nData.pswagaintips"
            autocomplete="off"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item class="formItem">
          <el-button
            class="submitBtn"
            type="primary"
            @click="submitForm('formData')"
            >{{ i18nData.button }}</el-button
          >
        </el-form-item>
        <div class="toOther">
          <span>{{ i18nData.loginTips }}</span>
          <span @click="toPage">{{ i18nData.login }}</span>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { loginfunc, getcodefunc } from "./../common/top.mixin.js";

export default {
  name: "forgetpwd",
  mixins: [loginfunc, getcodefunc],
  components: {},
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        // if (this.formData.password !== "") {
        //   this.$refs.formData.validateField("password");
        // }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入新密码"));
      } else if (value !== this.formData.password) {
        callback(new Error("两次输入新密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      activeType: "phone",
      eventType: "resetpwd",
      formData: {
        phone: "",
        email: "",
        password: "",
        pswdAgain: "",
        code: ""
      },
      rules: {
        pswdAgain: [{ validator: validatePass2, trigger: "blur" }],
        phone: [{ validator: checkPhone, trigger: "blur" }],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: "blur"
          }
        ],
        password: [{ validator: validatePass, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }]
      }
    };
  },
  created() {
    this.$store.state.activeIndex = "";
    this.$store.state.myActiveIndex = this.$route.name;
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
      if (this.activeType == "phone") {
        this.formData.email = "";
      } else {
        this.formData.phone = "";
      }
      this.$refs["formData"].clearValidate();
    },
    submitForm(formName) {
      console.log(this.$refs[formName]);
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const type = this.activeType == "phone" ? "mobile" : this.activeType;
          let params = {
            type: type,
            mobile: this.formData.phone,
            email: this.formData.email,
            newpassword: this.formData.password,
            captcha: this.formData.code
          };
          let res = await this.$api.https.goResetpwd(params);
          if (!res.code) {
            this.$message({
              message: res.msg,
              type: "error",
              offset: 40
            });
            return;
          }
          this.$message({
            message: res.msg,
            type: "success",
            offset: 40
          });
          this.success(3);
          // this.$router.push({ path: "/my" });
        } else {
          this.$message({
            message: "表单验证失败",
            type: "error",
            offset: 40
          });
          return false;
        }
      });
    },
    toPage() {
      this.$router.replace({ path: "/login" });
    }
  }
};
</script>
<style lang="scss" scoped>
$vue_color: #0495f3;
.changePswd {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  text-align: left;
  padding: 80px 0 60px 40px;
  .changeBox {
    width: 450px;
    padding: 40px 0;
    border: 1px solid #dcdcdc99;
    .formBox {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .typeTabs {
        margin-bottom: 10px;
      }
      ::v-deep.el-tabs__nav-wrap::after {
        background-color: transparent;
      }
      .formItem {
        // margin-bottom: 5px;
        .input {
          width: 280px;
        }
        ::v-deep.el-input__inner {
          border-radius: 2px;
          background: #ebebeb;
        }
      }
      .codeBox {
        display: flex;
        width: 280px;
        .formItem {
          .input {
            width: 140px;
          }
        }
        .code-img {
          flex: 1;
          ::v-deep .el-button--primary.is-plain {
            width: 100%;
            background-color: #ffffff;
            border-color: $vue_color;
            color: $vue_color !important;
          }
          ::v-deep .el-button--primary.is-plain:focus,
          .el-button--primary.is-plain:hover {
            background-color: $vue_color;
            color: #ffffff !important;
            transition: 0.5s;
          }
          ::v-deep .el-button--primary.is-plain.is-disabled,
          .el-button--primary.is-plain.is-disabled:active,
          .el-button--primary.is-plain.is-disabled:focus,
          .el-button--primary.is-plain.is-disabled:hover {
            background-color: #ebeef5;
            border-color: #c0c4cc;
            color: $vue_color;
          }
        }
      }
      .submitBtn {
        background-color: $vue_color;
        border-color: $vue_color;
        height: 36px;
        padding: 0 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        border-radius: 2px;
        margin-top: 24px;
      }
      .toOther {
        font-size: 14px;
        span:nth-child(2) {
          margin-left: 10px;
          color: $vue_color;
        }
      }
    }
  }
}
</style>
